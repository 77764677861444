<template>
  <b-modal :title="`Detalle ${resourceName}`"
           ref="modal-form"
           size="lg"
           v-model="show"
           @hide="CloseModal"
  >
    <b-container fluid>
      <!-- Form with Model -->
      <a-form-model
        ref="rule-Form"
        :model="form"
        :rules="rules"
        layout="vertical"
        :wrapper-col="{ span: 24}"
      >
        <a-form-model-item ref="client" label="Cliente" prop="client" >
          <a-select v-model="form.client_id"
                    placeholder="Seleccionar Cliente"
                    show-search
                    :filter-option="filterOption"
                    :disabled="clientID!== undefined"
          >
            <a-select-option  v-for="option in clients" :key="option.jsonApi.id" :value="option.jsonApi.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="code" label="Clave del Recolector" prop="code" >
          <a-input v-model.trim="form.code" disabled/>
        </a-form-model-item>
        <a-form-model-item ref="name" label="Nombre del Recolector" prop="name" >
          <a-input v-model.trim="form.name"/>
        </a-form-model-item>
        <a-form-model-item ref="phone" label="Teléfono (XX-XXXX-XXXX)" prop="phone" >
          <a-input v-model.trim="form.phone"/>
        </a-form-model-item>
        <a-form-model-item ref="email" label="Correo Electrónico" prop="email" >
          <a-input v-model.trim="form.email"/>
        </a-form-model-item>
        <a-form-model-item ref="contact" label="Contacto" prop="contact" >
          <a-input v-model.trim="form.contact"/>
        </a-form-model-item>
        <a-form-model-item ref="address" label="Dirección" prop="address" >
          <a-input v-model.trim="form.address"/>
        </a-form-model-item>
        <a-form-model-item ref="comments" label="Comentarios" prop="comments" >
          <a-input v-model.trim="form.comments"/>
        </a-form-model-item>
        <div class="row justify-content-center" v-show="!load">
          <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
          <responsive-button v-if="id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
        </div>

      </a-form-model>
      <!-- Form with Model -->

      <!-- Spinner -->
      <my-spinner :load="load"/>

    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseModal"/>
    </template>
  </b-modal>
</template>

<script>
import { objectArray } from '@/helpers/jsonApiTransformer'
import { Modal, notification } from 'ant-design-vue'

export default {
  name: 'modalContent',
  props: {
    // Muestra - oculta el modal
    modalShow: {
      default: false,
      type: [Boolean],
      required: true,
    },
    // Nombre del recurso
    resourceName: {
      type: [String],
      required: true,
    },
    // Arreglo para el formulario
    modalData: {
      type: [Object],
      required: true,
    },
    // Arreglo para el formulario
    clientID: {
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      id: '0',
      form: {
        name: '',
        code: '',
        client_id: undefined,
        phone: '',
        email: '',
        contact: '',
        address: '',
        comments: '',
      },
      // Relaciones
      relationships: {
        clients: {
          data: { id: 0, type: 'clients' },
        },
      },
      // arrays selects
      clients: [],
      // Reglas de validación
      rules: {
        name: [
          { required: true, message: 'El campo Nombre del Recolector es obligatorio.', trigger: 'blur' },
          { min: 3, max: 500, message: 'El campo Nombre del Recolector debe contener entre 3 y 500 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Nombre del Recolector debe ser de tipo texto.', trigger: 'blur' },
        ],
        client_id: [
          { required: true, message: 'El campo Cliente es obligatorio.', trigger: 'blur' },
        ],
        phone: [
          { min: 10, max: 20, message: 'El campo Número Telefónico debe contener entre 10 y 20 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Número Telefónico debe ser de tipo numérico.', trigger: 'blur' },
          { type: 'regexp', regexp: '/^[0-9]{2}-[0-9]{4}-[0-9]{4}$/', message: 'El campo teléfono debe tener el formato XX - XXXX-XXXX' },
        ],
        email: [
          { max: 200, message: 'El campo  Correo Electrónico debe contener menos de 200 caracteres.', trigger: 'blur' },
          { type: 'email', message: 'El campo Correo Electrónico no es válido.', trigger: 'blur' },
        ],
        contact: [
          { min: 2, max: 300, message: 'El campo Contacto debe contener entre 2 y 300 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Contacto debe ser de tipo texto.', trigger: 'blur' },
        ],
        address: [
          { min: 2, max: 400, message: 'El campo Dirección debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Dirección debe ser de tipo texto.', trigger: 'blur' },
        ],
        comments: [
          { type: 'string', message: 'El campo Detalles debe ser de tipo texto.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  computed: {
    show: {
      get() {
        return this.modalShow
      },
      set(newValue) {
        return newValue
      },
    },
  },
  watch: {
    modalShow(newValue) {
      if (newValue) {
        this.OpenModal()
      }
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get array selects data  -->
    getClients() {
      this.$store.dispatch('jv/get', ['clients', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.clients = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getClients()
      // const newData = data
      if (Object.keys(data).length === 0 && Object.keys(this.modalData).length === 0) {
        //  Store case
        this.id = '0'
        this.form.name = ''
        this.form.code = ''
        this.form.phone = ''
        this.form.email = ''
        this.form.contact = ''
        this.form.address = ''
        this.form.comments = ''
        // this.form.client_id = undefined
        this.form.client_id = (this.clientID !== undefined) ? this.clientID : undefined
      } else {
        // Update case
        this.id = this.modalData.jsonApi.id
        this.form.name = this.modalData.name
        this.form.code = this.modalData.code
        this.form.phone = this.modalData.phone
        this.form.email = this.modalData.email
        this.form.contact = this.modalData.contact
        this.form.address = this.modalData.address
        this.form.comments = this.modalData.comments
        this.form.client_id = (this.clientID !== undefined) ? this.clientID : this.modalData.clients.jsonApi.id
      }
    },
    CloseModal() {
      this.id = '0'
      this.resetForm()
      this.$emit('Close')
    },
    getData() {
      this.$emit('getData')
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          // build the relationships with the form data
          this.relationships.clients.data.id = this.form.client_id
          if (this.id === '0') {
            // Store Object
            formObject = {
              code: this.form.code,
              name: this.form.name,
              jsonApi: {
                type: 'collectors',
                relationships: this.relationships,
              },
            }
            if (this.form.phone !== '') formObject.phone = this.form.phone
            if (this.form.email !== '') formObject.email = this.form.email
            if (this.form.contact !== '') formObject.contact = this.form.contact
            if (this.form.address !== '') formObject.address = this.form.address
            if (this.form.comments !== '') formObject.comments = this.form.comments
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              code: this.form.code,
              name: this.form.name,
              jsonApi: {
                type: 'collectors',
                id: this.id,
                relationships: this.relationships,
              },
            }
            if (this.form.phone !== '') formObject.phone = this.form.phone
            if (this.form.email !== '') formObject.email = this.form.email
            if (this.form.contact !== '') formObject.contact = this.form.contact
            if (this.form.address !== '') formObject.address = this.form.address
            if (this.form.comments !== '') formObject.comments = this.form.comments
            // Update Object
          }
          // Reset relationships
          this.resetRelathionships()
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: 'collectors',
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetRelathionships() {
      this.relationships = {
        clients: {
          data: { id: 0, type: 'clients' },
        },
      }
    },
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
}
</script>

<style scoped>

</style>
