<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="code" label="Clave de Puesto de Empleado" prop="code" >
            <a-input v-model.trim="form.code"/>
          </a-form-model-item>
          <a-form-model-item ref="name" label="Puesto de Empleado" prop="name" >
            <a-input v-model.trim="form.name"/>
          </a-form-model-item>
          <a-form-model-item ref="description" label="Descripción" prop="description" >
            <a-input v-model.trim="form.description"/>
          </a-form-model-item>
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" class="mx-2" pill  @ClickEvent="onSubmit" size="sm" responsive="md" icon="archive-fill" text="Guardar"/>
            <responsive-button variant="danger" class="mx-2" pill  @ClickEvent="onDelete" size="sm" responsive="md" icon="trash" text="Eliminar"/>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal" />
      </template>
    </b-modal>
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject"
                          @Modal="OpenModal" @Filter="getData"
                          @searchInput="searchInput = $event"
    />
    <!-- Tabla -->
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogTable from '../components/table'
import DetailCatalogBar from '../components/searchBar'
import { notification, Modal } from 'ant-design-vue'
export default {
  name: 'employeePositionForm',
  components: {
    DetailCatalogTable,
    DetailCatalogBar,
  },
  data() {
    return {
      // <!-- Constantes -->
      resource: 'employeePositions',
      resourceName: 'Puestos de Empleados',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Puestos de Empleados',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Clave de Puesto de Empleado',
            dataIndex: 'code',
            key: 'code',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Puesto de Empleado',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.description.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'name',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'code', text: 'Clave de Puesto de Empleado' },
        { value: 'name', text: 'Puesto de Empleado' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->

      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        name: '',
        code: '',
        description: '',
      },
      rules: {
        name: [
          { required: true, message: 'El campo Puesto de Empleado es obligatorio.', trigger: 'blur' },
          // { min: 3, max: 400, message: 'El campo Nombre debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
        ],
        code: [
          { required: true, message: 'El campo Clave de Puesto de Empleado es obligatorio.', trigger: 'blur' },
          // { min: 3, max: 400, message: 'El campo Clave debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
        ],
        description: [
          { required: true, message: 'El campo Descripción es obligatorio.', trigger: 'blur' },
          // { min: 3, max: 400, message: 'El campo Clave debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }

      this.tableObject.loading = true
      this.$store.dispatch('jv/get', ['employeePositions', {
        params: {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
          'filter[search]': `${this.searchValue}`,
        },
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.id = '0'
        this.form.name = ''
        this.form.code = ''
        this.form.description = ''
      } else {
        // Update case
        this.id = data.jsonApi.id
        this.form.name = data.name
        this.form.code = data.code
        this.form.description = data.description
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.form.name = ''
      this.form.code = ''
      this.form.description = ''
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          if (this.id === '0') {
            // Store Object
            formObject = {
              name: this.form.name,
              code: this.form.code,
              description: this.form.description,
              jsonApi: {
                type: this.resource,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              name: this.form.name,
              code: this.form.code,
              description: this.form.description,
              jsonApi: {
                type: this.resource,
                id: this.id,
              },
            }
            // Update Object
          }
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })

          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          me.load = true
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              me.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetForm() {
      this.$refs.ruleForm.resetFields()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>

</style>
