<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="name" label="Nombre Turno de Operación" prop="name" >
            <a-input v-model.trim="form.name"/>
          </a-form-model-item>
          <a-row>
            <a-col :md="{ span: 12 }" :sm="{ span: 24 }">
              <a-form-model-item ref="initial_time" label="Hora Inicial" prop="initial_time" >
                <a-time-picker format="HH:mm" v-model="form.initial_time" style="width: 100%"/>
              </a-form-model-item>
            </a-col>
            <a-col :md="{ span: 12 }" :sm="{ span: 24 }">
              <a-form-model-item ref="final_time" label="Hora Final" prop="final_time" >
                <a-time-picker format="HH:mm" v-model="form.final_time" style="width: 100%"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item ref="workCenter" label="Centro de Trabajo" prop="workCenter" >
            <a-select v-model="form.work_center_id"
              placeholder="Seleccionar el Centro de Trabajo"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in work_centers" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="technical_id" label="Técnico de Operaciones" prop="technical_id" >
            <a-select v-model="form.technical_id"
              placeholder="Seleccionar el Técnico"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in technicals" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="analyst_id" label="Analista de Laboratorio" prop="analyst_id" >
            <a-select v-model="form.analyst_id"
              placeholder="Seleccionar el Analista"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in analysts" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
            <responsive-button v-if="this.id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject"
                          @Modal="OpenModal" @Filter="getData"
                          @searchInput="searchInput = $event"
    >
      <div slot="details" slot-scope="record">
        <small><strong>Centro de Trabajo: </strong>{{record.record.workCenters.name}}<br></small>
        <small><strong>Técnico de Operaciones: </strong>{{record.record.technicals.name}}<br></small>
        <small><strong>Analista de Laboratorios: </strong>{{record.record.analysts.name}}<br></small>
      </div>
      <div slot="initialDate" slot-scope="record">
        {{ moment(record.record.initial_time).format('HH:mm') }}
      </div>
      <div slot="finalDate" slot-scope="record">
        {{ moment(record.record.final_time).format('HH:mm') }}
      </div>
    </detail-catalog-table>
  </div>
</template>

<script>
import 'moment/locale/es'
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogTable from '../components/table'
import DetailCatalogBar from '../components/searchBar'
import { notification, Modal } from 'ant-design-vue'
// import { apiRestMixin } from '@/mixins/apiRestMixin'
export default {
  name: 'operationShiftsForm',
  components: {
    DetailCatalogTable,
    DetailCatalogBar,
  },
  // mixins: [apiRestMixin],
  data() {
    return {
      resource: 'operationShifts',
      resourceName: 'Turno de Operación',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Turno de Operación',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Clave',
            dataIndex: 'code',
            key: 'code',
            class: 'small text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Nombre Turno de Operación',
            dataIndex: 'name',
            key: 'name',
            class: 'small text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Hora Inicial',
            dataIndex: 'initial_time',
            key: 'initial_time',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'customInitialDate',
            },
          },
          {
            title: 'Hora Final',
            dataIndex: 'final_time',
            key: 'final_time',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'customFinalDate',
            },
          },
          {
            title: 'Detalles',
            dataIndex: 'detail',
            key: 'detail',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            // fixed: 'right',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'name',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'code', text: 'Clave' },
        { value: 'name', text: 'Nombre Turno de Operación' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        code: '',
        name: '',
        initial_time: '',
        final_time: '',
        work_center_id: undefined,
        technical_id: undefined,
        analyst_id: undefined,
      },
      // Relaciones
      relationships: {
        workCenters: {
          data: { id: 0, type: 'workCenters' },
        },
        technicals: {
          data: { id: 0, type: 'users' },
        },
        analysts: {
          data: { id: 0, type: 'users' },
        },
      },
      // arrays selects
      work_centers: [],
      technicals: [],
      analysts: [],
      // Reglas de validación
      rules: {
        work_center_id: [
          { required: true, message: 'El campo Centro de Trabajo es obligatorio.', trigger: 'blur' },
        ],
        name: [
          { required: true, message: 'El campo Nombre Turno de Operación es obligatorio.', trigger: 'blur' },
          { min: 3, max: 400, message: 'El campo Nombre debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Nombre debe ser de tipo texto.', trigger: 'blur' },
        ],
        // initial_time: [
        //   { required: true, message: 'El campo Hora Inicial es obligatorio.', trigger: 'blur' },
        // ],
        // final_time: [
        //   { required: true, message: 'El campo Hora Final es obligatorio.', trigger: 'blur' },
        // ],
        technical_id: [
          { required: true, message: 'El campo Técnico de Operaciones es obligatorio.', trigger: 'blur' },
        ],
        analyst_id: [
          { required: true, message: 'El campo Analista de Laboratorio es obligatorio.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          'filter[search]': `${this.searchValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
          include: 'workCenters,technicals,analysts',
        },
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    getWork_centers() {
      this.$store.dispatch('jv/get', ['workCenters', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.work_centers = objectArray(tableData)
        })
    },
    getTechnicals() {
      this.$store.dispatch('jv/get', ['users', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.technicals = objectArray(tableData)
          this.analysts = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getWork_centers()
      this.getTechnicals()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.id = '0'
        this.code = ''
        this.name = ''
        this.initial_time = ''
        this.final_time = ''
        this.work_center_id = undefined
        this.technical_id = undefined
        this.analyst_id = undefined
      } else {
        console.log(data.initial_time)
        console.log(data.final_time)
        // Update case
        this.id = data.jsonApi.id
        this.form.code = data.code
        this.form.name = data.name
        this.form.initial_time = data.initial_time
        this.form.final_time = data.final_time
        this.form.work_center_id = data.workCenters.jsonApi.id
        this.form.technical_id = data.technicals.jsonApi.id
        this.form.analyst_id = data.analysts.jsonApi.id
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          // build the relationships with the form data
          this.relationships.workCenters.data.id = this.form.work_center_id
          this.relationships.technicals.data.id = this.form.technical_id
          this.relationships.analysts.data.id = this.form.analyst_id
          if (this.id === '0') {
            // Store Object
            formObject = {
              code: `${this.form.code}`,
              name: `${this.form.name}`,
              initial_time: `${this.form.initial_time}`,
              final_time: `${this.form.final_time}`,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              code: `${this.form.code}`,
              name: `${this.form.name}`,
              initial_time: `${this.form.initial_time}`,
              final_time: `${this.form.final_time}`,
              jsonApi: {
                type: this.resource,
                id: this.id,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Reset relationships
          this.resetRelathionships()
          // Envio del Objecto
          this.load = true
          console.log(action)
          console.log(formObject)
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetRelathionships() {
      this.relationships = {
        workCenters: {
          data: { id: 0, type: 'workCenters' },
        },
        technicals: {
          data: { id: 0, type: 'users' },
        },
        analysts: {
          data: { id: 0, type: 'users' },
        },
      }
    },
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
