import { render, staticRenderFns } from "./familiesForm.vue?vue&type=template&id=221a8eaf&scoped=true&"
import script from "./familiesForm.vue?vue&type=script&lang=js&"
export * from "./familiesForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221a8eaf",
  null
  
)

export default component.exports