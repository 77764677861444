<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="globalValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="client" label="Cliente" prop="client" >
            <a-select v-model="form.client_id"
              placeholder="Seleccionar el Cliente"
              show-search
              :filter-option="filterOption"
              @focus="handleFocus"
              @blur="handleBlur"
              @change="handleChange"
            >
              <a-select-option  v-for="option in clients" :key="option.jsonApi.id" :value="option.jsonApi.id" >
              {{option.sap_code}}-{{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="contract_code" label="Clave del Contrato" prop="contract_code" >
            <a-input v-model.trim="form.contract_code"/>
          </a-form-model-item>
          <a-form-model-item ref="sap_route" label="Ruta" prop="sap_route" >
            <a-input v-model.trim="form.sap_route"/>
          </a-form-model-item>
          <div>
            <label for="initial_dated_at at">Fecha Inicial del Contrato</label>
            <b-form-datepicker id="initial_dated_at" v-model="form.initial_dated_at" class="mb-2"></b-form-datepicker>
          </div>
          <br>
          <div>
            <label for="final-dated-at">Fecha Final del Contrato</label>
            <b-form-datepicker id="final-dated-at" v-model="form.final_dated_at" class="mb-2"></b-form-datepicker>
          </div>
          <label for="isActive" class="pt-4">Contrato Activo</label>
          <b-form-select
            v-model="form.is_active"
            :options="isActiveOptions"
            class="mb-3"
            value-field="item"
            text-field="name"
          />
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
            <responsive-button v-if="this.id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
          </div>
        </a-form-model>
        <my-spinner :load="load"/>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject"
                          @Modal="OpenModal" @Filter="getData"
                          @searchInput="searchInput = $event"
    >
      <div slot="details" slot-scope="record">
        <div class="row">
          <div class="col-4 text-right">
            <small><strong>Cliente:</strong></small>
          </div>
          <div class="col-8 text-left">
            <small>{{record.record.clients.code}} - {{record.record.clients.name}}</small>
          </div>
        </div>
      </div>
    </detail-catalog-table>
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogTable from '../components/table'
import DetailCatalogBar from '../components/searchBar'
import { notification, Modal } from 'ant-design-vue'
export default {
  name: 'clientContractForm',
  components: {
    DetailCatalogTable,
    DetailCatalogBar,
  },
  data() {
    return {
      // <!-- Constantes -->
      isActiveOptions: [
        { item: true, name: 'SI' },
        { item: false, name: 'NO' },
      ],
      initial_dated_at: '',
      final_dated_at: '',
      resource: 'clientContracts',
      resourceName: 'Contratos de Clientes',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Contratos de Clientes',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Clave',
            dataIndex: 'contract_code',
            key: 'contract_code',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.contract_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Ruta',
            dataIndex: 'sap_route',
            key: 'sap_route',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.sap_route.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Fecha Inicial',
            dataIndex: 'initial_dated_at',
            key: 'initial_dated_at',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.initial_dated_at.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Fecha Final',
            dataIndex: 'final_dated_at',
            key: 'final_dated_at',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.final_dated_at.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Contrato Activo',
            label: 'Activo',
            key: 'is_active',
            class: 'text-center',
            scopedSlots: {
              customRender: 'isActive',
            },
          },
          {
            title: 'Detalles',
            dataIndex: 'client',
            key: 'client',
            class: 'text-center',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center',
            key: 'x',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      globalValue: '',
      sortByValue: '',
      sortByDefaultValue: 'contract_code',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'contract_code', text: 'Clave de Contrato' },
        { value: 'initial_dated_at', text: 'Fecha Inicial' },
        { value: 'final_dated_at', text: 'Fecha Final' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        client_id: undefined,
        contract_code: '',
        sap_route: '',
        initial_dated_at: '',
        final_dated_at: '',
        is_active: false,
      },
      // Relaciones
      relationships: {
        clients: {
          data: { id: 0, type: 'clients' },
        },
      },
      // arrays selects
      clients: [],
      // Reglas de validación
      rules: {
        client_id: [
          { required: true, message: 'El campo Cliente es obligatorio.', trigger: 'blur' },
        ],
        contract_code: [
          { required: true, message: 'El campo Clave de Contrato es obligatorio.', trigger: 'blur' },
          { min: 3, max: 20, message: 'El campo Clave de Contrato debe contener entre 3 y 20 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Clave de Contrato debe ser de tipo texto.', trigger: 'blur' },
        ],
        sap_route: [
          { required: true, message: 'El campo Ruta es obligatorio.', trigger: 'blur' },
          { max: 500, message: 'El campo Ruta debe contener máximo 500 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Ruta debe ser de tipo texto.', trigger: 'blur' },
        ],
        initial_dated_at: [
          { required: true, message: 'El campo Fecha Inicial es obligatoria.', trigger: 'blur' },
          { min: 2, max: 20, message: 'El campo Fecha Inicial debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'date', message: 'El campo Fecha Inicial debe ser de tipo fecha.', trigger: 'blur' },
        ],
        final_dated_at: [
          { required: true, message: 'El campo Fecha Final es obligatoria.', trigger: 'blur' },
          { min: 2, max: 20, message: 'El campo Fecha Final debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'date', message: 'El campo Fecha Final debe ser de tipo fecha.', trigger: 'blur' },
        ],
        is_active: [
          { required: true, message: 'El campo Mezcla es obligatorio.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    handleBlur() {
      console.log('blur')
    },
    handleFocus() {
      console.log('focus')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          'filter[global]': `${this.globalValue}`,
          // sort: `${this.sortDirection}${this.sortValue}`,
          include: 'clients',
        },
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    getClients() {
      this.$store.dispatch('jv/get', ['clients', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.clients = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getClients()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.id = '0'
        this.form.client_id = undefined
        this.form.contract_code = ''
        this.form.initial_dated_at = ''
        this.form.final_dated_at = ''
        this.form.is_active = false
        this.form.sap_route = ''
      } else {
        // Update case
        this.id = data.jsonApi.id
        this.form.contract_code = data.contract_code
        this.form.initial_dated_at = data.initial_dated_at
        this.form.final_dated_at = data.final_dated_at
        this.form.is_active = data.is_active
        this.form.client_id = data.clients.jsonApi.id
        this.form.sap_route = data.sap_route
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          // build the relationships with the form data
          this.relationships.clients.data.id = this.form.client_id
          if (this.id === '0') {
            // Store Object
            formObject = {
              contract_code: `${this.form.contract_code}`,
              initial_dated_at: `${this.form.initial_dated_at}`,
              final_dated_at: `${this.form.final_dated_at}`,
              is_active: this.form.is_active,
              sap_route: this.form.sap_route,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              contract_code: `${this.form.contract_code}`,
              initial_dated_at: this.form.initial_dated_at,
              final_dated_at: `${this.form.final_dated_at}`,
              is_active: this.form.is_active,
              sap_route: this.form.sap_route,
              jsonApi: {
                type: this.resource,
                id: this.id,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Reset relationships
          this.resetRelathionships()
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetRelathionships() {
      this.relationships = {
        clients: {
          data: { id: 0, type: 'clients' },
        },
      }
    },
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
