<template>
  <div>
    <a-table
      :scroll="{ x: 1300, y: 1000}"
      :dataSource="tableObject.data"
      :columns="tableObject.columns"
      :loading="tableObject.loading"
      :row-key="record => record.code"
      :pagination="tableObject.pagination"
      @change="refreshResults"
    >
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <div slot="work_center" slot-scope="row">
        <span v-if="Object.keys(row).length">
          {{ row.workCenterRelation.code }} - {{ row.workCenterRelation.name }}
        </span>
      </div>

      <div slot="mixture" slot-scope="row">
        <b-icon icon="check-circle-fill" variant="success" v-if="row.mixture==true"/>
        <b-icon icon="x-circle-fill" variant="danger" v-else/>
      </div>

      <div slot="dangerous_type" slot-scope="row">
        {{ row.dangerousTypeRelation.name }}
      </div>

      <div slot="current" slot-scope="row">
        <strong>
          {{ row.currentRelation.code }} - {{ row.currentRelation.name }}
        </strong>
      </div>

      <div slot="details" slot-scope="data">
        <div class="row my-0 py-0">
          <div class="col-6 text-left">
            <strong>Planta : </strong>
          </div>
          <div class="col-6 text-right">
            <span> {{data.workCenterRelation.name}}</span>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row my-0 py-0">
          <div class="col-6 text-left">
            <strong>Peligrosidad : </strong>
          </div>
          <div class="col-6 text-right">
            <span> {{data.dangerousTypeRelation.name}}</span>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row my-0 py-0">
          <div class="col-6 text-left">
            <strong>Corriente : </strong>
          </div>
          <div class="col-6 text-right">
            <span> {{ data.currentRelation.code }} - {{ data.currentRelation.name }}</span>
          </div>
        </div>
        <hr class="bg-primary">
        <div class="row my-0 py-0">
          <div class="col-6 text-right">
            <strong>Mezcla : </strong>
          </div>
          <div class="col-6 text-left">
            <b-icon icon="check-circle-fill" variant="success" v-if="data.mixture"/>
            <b-icon icon="x-circle-fill" variant="danger" v-else/>
          </div>
        </div>
      </div>

      <div slot="clients" slot-scope="clients">
        <ul v-if="Object.keys(clients).length">
          <li v-for="client in clients" :key="client.code">{{client.name}}</li>
        </ul>
      </div>

      <div slot="rme_rp" slot-scope="row">
        <small>
          {{ row.rmeRpRelation.code }} - {{ row.rmeRpRelation.description }}
        </small>
      </div>

      <div slot="action" slot-scope="row">
        <responsive-button variant="secondary" pill size="sm" responsive="md" text="Editar" icon="pencil-square" @ClickEvent="showModal('edit',row)"/>
      </div>
    </a-table>

  </div>
</template>

<script>
export default {
  name: 'MaterialDetailCatalogTable',
  props: {
    tableObject: {
      type: [Object],
      required: true,
    },
  },
  data () {
    return {
    }
  },
  watch: {
    searchInput(newValue) {
      this.$emit('searchInput', newValue)
    },
  },
  methods: {
    showModal(action, data = {}) {
      this.$emit('Modal', action, data)
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
  },
  mounted() {
  },
}
</script>
