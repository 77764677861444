<template>
    <div>
        <!--TITULOS  -->
        <div class="air__utils__heading"> <h5>Master de Empleados</h5> </div>
        <my-breadcrumbs :routes="routes"/>
        <!--TITULOS  -->
        <!--BOTONERA  -->
        <div class="row justify-content-end mr-2">
          <b-button-group>
            <b-button  variant="primary" @click="OpenModal()" pill>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
                <b-icon icon="plus"/> Nuevo
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
                <b-icon icon="plus"/>
              </div>
            </b-button>
          </b-button-group>
        </div>
        <!--BOTONERA  -->
        <!--BARRA DE BUSQUEDA  -->
        <searchingBar :components="['filter', 'sort', 'pages']"
              :sortByOptions="sortByOptions"
              @Fectch="FetchTable"
              @filterValue="filterValue = $event"
              @sortValue="tableObj.sortValue = $event"
              @pageSize="tableObj.pagination.pageSize = $event"
              @currentPageValue="tableObj.currentPage = $event"/>
        <!--BARRA DE BUSQUEDA  -->
        <!--TABLA-->
        <a-table :columns="tableObj.columns"
            :data-source="tableObj.data"
            :pagination="tableObj.pagination"
            :loading="tableObj.loading"
            @change="FetchTable"
            :row-key="record => record.id">
          <template slot="action" slot-scope="data">
            <b-button  variant="secondary" @click="OpenModal(data)" pill>
                <div class="d-none d--block" v-b-tooltip.hover title="Editar">
                    <b-icon icon="pencil-square"/> Editar
                </div>
                <div class="d--none" v-b-tooltip.hover title="Editar">
                    <b-icon icon="pencil-square"/>
                </div>
            </b-button>
          </template>

          <div slot="details" slot-scope="record">
            <small><strong>Centro de Trabajo: </strong>{{record.record.workCenters.name}}<br></small>
            <small><strong>Posición de Empleado: </strong>{{record.record.employeePositions.name}}<br></small>
            <small><strong>Área de Operación: </strong>{{record.record.operationAreas.name}}<br></small>
            <small><strong>Familia: </strong>{{record.record.families.name}}<br></small>
            <small><strong>Empresa Contratista: </strong>{{record.record.contractorCompanies.name}}</small>
          </div>

        </a-table>
        <!--TABLA-->
        <!-- MODAL -->
        <b-modal title="Detalle de Master de Empleados" ref="modal-Form" size="lg" @hide="CloseModal">
          <my-spinner :load="spinnerLoad"/>
          <b-container fluid v-show="!spinnerLoad">
              <!--FORMULARIO-->
              <a-form-model :model="resourceObj.form" :rules="formRules" ref="ruleForm"
                  layout="vertical" :wrapper-col="{ span: 24}">

                  <!--SELECT -Centro de Trabajo -->
                <a-form-model-item label="Centro de Trabajo" ref="work_center_id" prop="work_center_id" >
                    <a-select v-model="resourceObj.form.work_center_id"
                                placeholder="Centro de Trabajo"
                                show-search :filter-option="filterOption">
                        <a-select-option  v-for="option in typesCombo" :key="option.value" :value="option.value" >
                          {{option.label}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <!--SELECT -Centro de Trabajo -->
                <!--DATEPICKER -Fecha de Entrada -->
                <a-row align="middle" justify="center">
                  <a-col :sm="{ span: 24 }" :md="{ span:12}">
                    <a-form-model-item  label="Fecha de Entrada" ref="entry_dated_at" prop="entry_dated_at" >
                      <a-date-picker  v-model.trim="resourceObj.form.entry_dated_at" placeholder="Fecha de entrada"
                                  format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
                    </a-form-model-item>
                  </a-col>
                  <a-col :sm="{ span: 24 }" :md="{ span:12}">
                    <a-form-model-item label="Clave" ref="code"  prop="code" >
                      <a-input v-model.trim="resourceObj.form.code" placeholder="Clave" size="large"/>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <!--TEXT -Clave -->
                <!--TEXT - Nombre del Master de Empleados-->
                <a-form-model-item label="Nombre de Master de Empleados" ref="name"  prop="name" >
                    <a-input v-model.trim="resourceObj.form.name" placeholder="Nombre de Master de Empleados" size="large"/>
                </a-form-model-item>
                <!--TEXT -Nombre del Master de Empleados -->
                <!--SELECT -Posición de Empleado -->
                <a-form-model-item label="Posición de Empleado" ref="employee_position_id" prop="employee_position_id" >
                    <a-select v-model="resourceObj.form.employee_position_id"
                                placeholder="Posición de Empleado"
                                show-search :filter-option="filterOption">
                        <a-select-option  v-for="option in typesCombo" :key="option.value" :value="option.value" >
                          {{option.label}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <!--SELECT -Posición de Empleado -->
                <!--SELECT -Área de Operación -->
                <a-form-model-item label="Área de Operación" ref="operation_area_id" prop="operation_area_id" >
                    <a-select v-model="resourceObj.form.operation_area_id"
                                placeholder="Área de Operación"
                                show-search :filter-option="filterOption">
                        <a-select-option  v-for="option in typesCombo" :key="option.value" :value="option.value" >
                          {{option.label}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <!--SELECT -Área de Operación -->
                <!--TEXT - Teléfono-->
                <a-form-model-item label="Teléfono" ref="phone"  prop="phone" >
                    <a-input v-model.trim="resourceObj.form.phone" placeholder="Teléfono" size="large"/>
                </a-form-model-item>
                <!--TEXT -Teléfono -->
                <!--TEXT - Dirección-->
                <a-form-model-item label="Dirección" ref="address"  prop="address" >
                    <a-input v-model.trim="resourceObj.form.address" placeholder="Dirección" size="large"/>
                </a-form-model-item>
                <!--TEXT -Dirección -->
                <!--SELECT -Familia -->
                <a-form-model-item label="Familia" ref="family_id" prop="family_id" >
                    <a-select v-model="resourceObj.form.family_id"
                                placeholder="Familia"
                                show-search :filter-option="filterOption">
                        <a-select-option  v-for="option in typesCombo" :key="option.value" :value="option.value" >
                          {{option.label}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <!--SELECT -Familia -->
                <!--SELECT -Empresa Contratista -->
                <a-form-model-item label="Empresa Contratista" ref="contractor_company_id" prop="contractor_company_id" >
                    <a-select v-model="resourceObj.form.contractor_company_id"
                                placeholder="Empresa Contratista"
                                show-search :filter-option="filterOption">
                        <a-select-option  v-for="option in typesCombo" :key="option.value" :value="option.value" >
                          {{option.label}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <!--SELECT -Empresa Contratista -->
                <!--NUMBER INPUT - Costo Hora-->
                  <a-form-model-item label="Costo Hora" ref="hour_cost"  prop="hour_cost">
                    <a-input-number v-model.trim="resourceObj.form.hour_cost" :min="0" :max="1000000" :step="1" size="large" style="width: 100%"/>
                  </a-form-model-item>
                  <!--NUMBER INPUT -Costo Hora -->

                <!--BOTONES -->
                <div class="row justify-content-end">
                  <b-button-group>
                    <b-button  variant="primary" @click="Submit" pill>
                      <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                        <b-icon icon="archive"/> Guardar
                      </div>
                      <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                        <b-icon icon="archive"/>
                      </div>
                    </b-button>
                    <b-button v-if="resourceObj.form.id"  variant="danger" @click="Delete" pill>
                        <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                            <b-icon icon="trash"/> Eliminar
                        </div>
                        <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                            <b-icon icon="trash"/>
                        </div>
                    </b-button>
                  </b-button-group>
                </div>
                <!--BOTONES -->
              </a-form-model>
              <!--FORMULARIO-->
            </b-container>
         <template v-slot:modal-footer>
            <simple-modal-footer @ClickEvent="CloseModal"/>
          </template>
        </b-modal>
        <!-- MODAL -->
    </div>
</template>
<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'operationalWorkersForm',
  mixins: [fractalMixin],
  data() {
    return {
      // rutas del breadcrumbs
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Master de Empleados',
          aIcon: '',
        },
      ],
      // valor del filtro search de la barra de busqueda
      filterValue: '',
      // Opciones de ordenamiento
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'name', text: 'Nombre' },
      ],
      // Objeto de tabla
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          // {
          //   title: 'Centro de Trabajo',
          //   dataIndex: 'work_center_id',
          //   class: 'small text-center',
          //   // fixed: 'left',
          //   // width: '20%',
          // },
          {
            title: 'Fecha de Entrada',
            dataIndex: 'entry_dated_at',
            class: 'small text-center',
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Clave',
            dataIndex: 'code',
            class: 'small text-center',
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Nombre de Master de Empleados',
            dataIndex: 'name',
            class: 'small text-center',
            // fixed: 'left',
            // width: '20%',
          },
          // {
          //   title: 'Posición de Empleado',
          //   dataIndex: 'employee_position_id',
          //   class: 'small text-center',
          //   // fixed: 'left',
          //   // width: '20%',
          // },
          // {
          //   title: 'Área de Operación',
          //   dataIndex: 'operation_area_id',
          //   class: 'small text-center',
          //   // fixed: 'left',
          //   // width: '20%',
          // },
          {
            title: 'Teléfono',
            dataIndex: 'phone',
            class: 'small text-center',
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Dirección',
            dataIndex: 'address',
            class: 'small text-center',
            // fixed: 'left',
            // width: '20%',
          },
          // {
          //   title: 'Familia',
          //   dataIndex: 'family_id',
          //   class: 'small text-center',
          //   // fixed: 'left',
          //   // width: '20%',
          // },
          // {
          //   title: 'Empresa Contratista',
          //   dataIndex: 'contractor_company_id',
          //   class: 'small text-center',
          //   // fixed: 'left',
          //   // width: '20%',
          // },
          {
            title: 'Costo Hora',
            dataIndex: 'hour_cost',
            class: 'small text-center',
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Detalles',
            dataIndex: 'detail',
            key: 'detail',
            class: 'small text-center',
            scopedSlots: { customRender: 'myCustomColumns' },
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Detalles',
            dataIndex: 'detail',
            key: 'detail',
            class: 'small',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            // width: '20%',
          },
        ],
      },
      // Objeto principal del recurso
      resourceObj: {
        resourceType: 'storageAreas',
        form: {
          id: '',
          name: '',
          area_type: undefined,
        },
        relationships: [],
      },
      // Reglas de Formulario
      formRules: {
        work_center_id: [{ required: true, message: 'El Centro de Trabajo es obligatorio.', trigger: 'blur' }],
        entry_dated_at: [{ required: true, message: 'La Fecha de Entrada es obligatorio.', trigger: 'blur' }],
        code: [{ required: true, message: 'ELa Clave es obligatorio.', trigger: 'blur' }],
        name: [{ required: true, message: 'El Nombre Master de Empleados es obligatorio.', trigger: 'blur' }],
        employee_position_id: [{ required: true, message: 'La Posición de Empleado es obligatorio.', trigger: 'blur' }],
        operation_area_id: [{ required: true, message: 'El Área de Operación es obligatorio.', trigger: 'blur' }],
        phone: [{ required: true, message: 'El Teléfono es obligatorio.', trigger: 'blur' }],
        address: [{ required: true, message: 'La Dirección es obligatorio.', trigger: 'blur' }],
        family_id: [{ required: true, message: 'La Familia es obligatorio.', trigger: 'blur' }],
        contractor_company_id: [{ required: true, message: 'La Empresa Contratista es obligatorio.', trigger: 'blur' }],
        hour_cost: [{ required: true, message: 'El Costo Hora es obligatorio.', trigger: 'blur' }],
      },
      // Combos de formulario
      // typesCombo: [{ value: 'Laboratorios', label: 'LABORATORIOS' }, { value: 'Operaciones', label: 'OPERACIONES' }],
      // Variables para el flujo del formulario
      spinnerLoad: false,
    }
  },
  methods: {
    async FetchTable(pagEvent = {}) {
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObj.pagination.current}` : `${this.tableObj.currentPage}`,
        'page[size]': `${this.tableObj.pagination.pageSize}`,
        sort: this.tableObj.sortValue,
        'filter[search]': this.filterValue,
      }
      await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
    },
    OpenModal(data = {}) {
      this.$refs['modal-Form'].show()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.resourceObj.form = {
          id: '',
          work_center_id: undefined,
          entry_dated_at: '',
          code: '',
          name: '',
          employee_position_id: undefined,
          operation_area_id: undefined,
          phone: '',
          address: '',
          family_id: undefined,
          contractor_company_id: undefined,
          hour_cost: '',
        }
      } else {
        // Update case
        this.resourceObj.form = {
          id: data.id.toString(),
          work_center_id: data.work_center_id,
          entry_dated_at: data.entry_dated_at,
          code: data.code,
          name: data.name,
          employee_position_id: data.employee_position_id,
          operation_area_id: data.operation_area_id,
          phone: data.phone,
          address: data.address,
          family_id: data.family_id,
          contractor_company_id: data.contractor_company_id,
          hour_cost: data.hour_cost,
        }
      }
    },
    CloseModal() {
      this.$refs['modal-Form'].hide()
      this.resourceObj.form = {
        id: '',
        work_center_id: undefined,
        entry_dated_at: '',
        code: '',
        name: '',
        employee_position_id: undefined,
        operation_area_id: undefined,
        phone: '',
        address: '',
        family_id: undefined,
        contractor_company_id: undefined,
        hour_cost: '',
      }
    },
    async Submit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            await this.PostResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else await this.PutResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async Delete() {
      await this.deleteQuestion(this.resourceObj.resourceType, this.resourceObj.form.id, this.SuccessCallback)
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>
<style scoped></style>
