<template>
    <div>
        <!--TITULOS  -->
        <div class="air__utils__heading"> <h5>Áreas de Almacenamiento</h5> </div>
        <my-breadcrumbs :routes="routes"/>
        <!--TITULOS  -->
        <!--BOTONERA  -->
        <div class="row justify-content-end mr-2">
          <b-button-group>
            <b-button  variant="primary" @click="OpenModal()" pill>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
                <b-icon icon="plus"/> Nuevo
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
                <b-icon icon="plus"/>
              </div>
            </b-button>
          </b-button-group>
        </div>
        <!--BOTONERA  -->
        <!--BARRA DE BUSQUEDA  -->
        <searchingBar :components="['filter', 'sort', 'pages']"
              :sortByOptions="sortByOptions"
              @Fectch="FetchTable"
              @filterValue="filterValue = $event"
              @sortValue="tableObj.sortValue = $event"
              @pageSize="tableObj.pagination.pageSize = $event"
              @currentPageValue="tableObj.currentPage = $event"/>
        <!--BARRA DE BUSQUEDA  -->
        <!--TABLA-->
        <a-table :columns="tableObj.columns"
            :data-source="tableObj.data"
            :pagination="tableObj.pagination"
            :loading="tableObj.loading"
            @change="FetchTable"
            :row-key="record => record.id">
          <template slot="action" slot-scope="data">
            <b-button  variant="secondary" @click="OpenModal(data)" pill>
                <div class="d-none d--block" v-b-tooltip.hover title="Editar">
                    <b-icon icon="pencil-square"/> Editar
                </div>
                <div class="d--none" v-b-tooltip.hover title="Editar">
                    <b-icon icon="pencil-square"/>
                </div>
            </b-button>
          </template>
        </a-table>
        <!--TABLA-->
        <!-- MODAL -->
        <b-modal title="Detalle de Área de almacenamiento" ref="modal-Form" size="lg" @hide="CloseModal">
          <my-spinner :load="spinnerLoad"/>
          <b-container fluid v-show="!spinnerLoad">
              <!--FORMULARIO-->
              <a-form-model :model="resourceObj.form" :rules="formRules" ref="ruleForm"
                  layout="vertical" :wrapper-col="{ span: 24}">
                <!--TEXT - Nombre del Área de almacenamiento-->
                <a-form-model-item label="Nombre del Área de almacenamiento" ref="name"  prop="name" >
                    <a-input v-model.trim="resourceObj.form.name" placeholder="Nombre del Área de almacenamiento" size="large"/>
                </a-form-model-item>
                <!--TEXT -Nombre del Área de almacenamiento -->
                <!--SELECT -Tipo de Área de Almacenamiento -->
                <a-form-model-item label="Tipo de Área de Almacenamiento" ref="area_type" prop="area_type" >
                    <a-select v-model="resourceObj.form.area_type"
                                placeholder="Tipo de Área de Almacenamiento"
                                show-search :filter-option="filterOption">
                        <a-select-option  v-for="option in typesCombo" :key="option.value" :value="option.value" >
                          {{option.label}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <!--SELECT -Tipo de Área de Almacenamiento -->
                <!--BOTONES -->
                <div class="row justify-content-end">
                  <b-button-group>
                    <b-button  variant="primary" @click="Submit" pill>
                      <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                        <b-icon icon="archive"/> Guardar
                      </div>
                      <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                        <b-icon icon="archive"/>
                      </div>
                    </b-button>
                    <b-button v-if="resourceObj.form.id"  variant="danger" @click="Delete" pill>
                        <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                            <b-icon icon="trash"/> Eliminar
                        </div>
                        <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                            <b-icon icon="trash"/>
                        </div>
                    </b-button>
                  </b-button-group>
                </div>
                <!--BOTONES -->
              </a-form-model>
              <!--FORMULARIO-->
            </b-container>
         <template v-slot:modal-footer>
            <simple-modal-footer @ClickEvent="CloseModal"/>
          </template>
        </b-modal>
        <!-- MODAL -->
    </div>
</template>
<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'storageAreasForm',
  mixins: [fractalMixin],
  data() {
    return {
      // rutas del breadcrumbs
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Áreas de Almacenamiento',
          aIcon: '',
        },
      ],
      // valor del filtro search de la barra de busqueda
      filterValue: '',
      // Opciones de ordenamiento
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'name', text: 'Nombre' },
      ],
      // Objeto de tabla
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Nombre',
            dataIndex: 'name',
            class: 'small text-center',
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Tipo de Área de Almacenamiento',
            dataIndex: 'area_type',
            class: 'small text-center',
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            // width: '20%',
          },
        ],
      },
      // Objeto principal del recurso
      resourceObj: {
        resourceType: 'storageAreas',
        form: {
          id: '',
          name: '',
          area_type: undefined,
        },
        relationships: [],
      },
      // Reglas de Formulario
      formRules: {
        name: [{ required: true, message: 'El Nombre del Área de almacenamiento es obligatorio.', trigger: 'blur' }],
        area_type: [{ required: true, message: 'El tipo de Área de almacenamiento es obligatorio.', trigger: 'blur' }],
      },
      // Combos de formulario
      typesCombo: [{ value: 'Laboratorios', label: 'LABORATORIOS' }, { value: 'Operaciones', label: 'OPERACIONES' }],
      // Variables para el flujo del formulario
      spinnerLoad: false,
    }
  },
  methods: {
    async FetchTable(pagEvent = {}) {
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObj.pagination.current}` : `${this.tableObj.currentPage}`,
        'page[size]': `${this.tableObj.pagination.pageSize}`,
        sort: this.tableObj.sortValue,
        'filter[search]': this.filterValue,
      }
      await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
    },
    OpenModal(data = {}) {
      this.$refs['modal-Form'].show()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.resourceObj.form = {
          id: '',
          name: '',
          area_type: undefined,
        }
      } else {
        // Update case
        this.resourceObj.form = {
          id: data.id.toString(),
          name: data.name,
          area_type: data.area_type,
        }
      }
    },
    CloseModal() {
      this.$refs['modal-Form'].hide()
      this.resourceObj.form = {
        id: '',
        name: '',
        area_type: undefined,
      }
    },
    async Submit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            await this.PostResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else await this.PutResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async Delete() {
      await this.deleteQuestion(this.resourceObj.resourceType, this.resourceObj.form.id, this.SuccessCallback)
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>
<style scoped></style>
