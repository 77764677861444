<template>
    <component :is="catalog"></component>
</template>

<script>
import accountingTypeForm from './accounting_types/form'
import StateForm from './states/form'
import IncidentTypeForm from './incident_types/form'
import transactionTypeForm from './transaction_types/form'
import disposalTypeForm from './disposal_types/form'
import incidentStatusForm from './incident_statuses/form'
import authorizationStatusForm from './authorization_statuses/form'
import sapMaterialForm from './sap_materials/form'
import shipmentServiceTypeForm from './shipment_service_types/form'
import logisticRequirementTypeForm from './logistic_requirement_types/form'
import accountingSpecialTypeForm from './accounting_special_types/form'
import currentForm from './currents/form'
import areaForm from './areas/form'
import workCenterForm from './work_centers/form'
import countryForm from './countries/form'
import containerTypeForm from './container_types/form'
import employeePositionForm from './employee_positions/form'
import clientTypeForm from './client_types/form'
import carrierCompanyForm from './carrier_companies/form'
import receiverForm from './receivers/form'
import clientContractForm from './client_contracts/form'
import clientForm from './clients/form'
import umForm from './u_m_s/form'
import servicetypeForm from './service_types/form'
import truckForm from './trucks/form'
import driverForm from './drivers/form'
import logisticshipmenttypeForm from './logistics_shipment_types/form'
import employeesettingForm from './employee_settings/form'
import contactForm from './contacts/form'
import shiftForm from './shifts/form'
import chargingTypeForm from './charging_types/form'
import collectorForm from './collectors/form'
import logisticRequirementForm from './logistic_requirements/form'
import materialForm from './materials/form'
import sapMaterialContractForm from './sap_material_contracts/form'
import dangerousTypeForm from './dangerous_types/form'
import rmeRpForm from './rme_rps/form'
import internalClientForm from './internal_clients/form'
import workCenterClientForm from './work_center_clients/form'
import excessTimeInPlantForm from './excess_time_in_plants/form'
import visualInspectionStatusForm from './visual_inspection_status/form'
import warehouseForm from './warehouses/form'
import dischargeMaterialIncidentForm from './discharge_material_incidents/form'
import inspectionStatusForm from './inspection_statuses/form'
import storageAreasForm from './storage_areas/storageAreasForm'
import operationAreasForm from './operation_areas/form'
import storageTimesForm from './storage_times/form'
// import contractorCompaniesForm from './contractor_companies/form'
import familiesForm from './families/familiesForm'
import suppliersForm from './suppliers/supplierForm'
import reagentBrandsForm from './reagent_brands/reagentBrandsForm'
import labTestsForm from './lab_tests/labTestsForm'
import operationalWorkersForm from './operational_workers/operationalWorkersForm'
import operationPositionsForm from './operation_positions/operationPositionForm'
import operationCompaniesForm from './operation_companies/operationCompanyForm'
import operationShiftsForm from './operation_shifts/form'
import labAllocationForm from './lab_allocations/form'
import labStorageTimeForm from './lab_storage_times/labStorageTimeForm'
import sampleContainerTypeForm from './sample_container_types/sampleContainerTypeForm'
import reagentProviderForm from './reagent_providers/reagentProviderForm'
import warehouseTransactionsForm from './warehouse_transactions/warehouseTransactionForm'
import formulationTypeForm from './formulation_types/formulationTypesForm'
import managementTypeForm from './management_types/managementTypesForm'
import shutdownReasonForm from './shutdown_reasons/shutdownReasonsForm'
import shutdownTypeForm from './shutdown_types/shutdownTypesForm'

export default {
  name: 'CatalogDetail',
  components: {
    accounting_types: accountingTypeForm,
    states: StateForm,
    incident_types: IncidentTypeForm,
    transaction_types: transactionTypeForm,
    disposal_types: disposalTypeForm,
    incident_statuses: incidentStatusForm,
    authorization_statuses: authorizationStatusForm,
    sap_materials: sapMaterialForm,
    shipment_service_types: shipmentServiceTypeForm,
    logistic_requirement_types: logisticRequirementTypeForm,
    accounting_special_types: accountingSpecialTypeForm,
    currents: currentForm,
    areas: areaForm,
    work_centers: workCenterForm,
    countries: countryForm,
    container_types: containerTypeForm,
    employee_positions: employeePositionForm,
    client_types: clientTypeForm,
    carrier_companies: carrierCompanyForm,
    receivers: receiverForm,
    client_contracts: clientContractForm,
    clients: clientForm,
    u_m_s: umForm,
    service_types: servicetypeForm,
    trucks: truckForm,
    drivers: driverForm,
    logistics_shipment_types: logisticshipmenttypeForm,
    employee_settings: employeesettingForm,
    contacts: contactForm,
    shifts: shiftForm,
    charging_types: chargingTypeForm,
    collectors: collectorForm,
    logistic_requirements: logisticRequirementForm,
    materials: materialForm,
    sap_material_contracts: sapMaterialContractForm,
    dangerous_types: dangerousTypeForm,
    rme_rps: rmeRpForm,
    internal_clients: internalClientForm,
    work_center_clients: workCenterClientForm,
    excess_time_in_plants: excessTimeInPlantForm,
    visual_inspection_statuses: visualInspectionStatusForm,
    warehouses: warehouseForm,
    discharge_material_incidents: dischargeMaterialIncidentForm,
    inspection_statuses: inspectionStatusForm,
    storage_areas: storageAreasForm,
    operation_areas: operationAreasForm,
    storage_times: storageTimesForm,
    // contractor_companies: contractorCompaniesForm,
    families: familiesForm,
    suppliers: suppliersForm,
    reagent_brands: reagentBrandsForm,
    lab_tests: labTestsForm,
    operational_workers: operationalWorkersForm,
    operation_positions: operationPositionsForm,
    operation_companies: operationCompaniesForm,
    operation_shifts: operationShiftsForm,
    lab_allocations: labAllocationForm,
    lab_storage_times: labStorageTimeForm,
    sample_container_types: sampleContainerTypeForm,
    reagent_providers: reagentProviderForm,
    warehouse_transactions: warehouseTransactionsForm,
    formulation_types: formulationTypeForm,
    management_types: managementTypeForm,
    shutdown_reasons: shutdownReasonForm,
    shutdown_types: shutdownTypeForm,
  },
  data () {
    return {
      catalog: this.$route.params.table,
    }
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }

  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
