<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="clientType" label="Tipo de Cliente" prop="clientType" >
            <a-select v-model="form.client_type_id"
              placeholder="Seleccionar el Tipo de Cliente"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in client_types" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="workCenter" label="Centro de Trabajo" prop="workCenter" >
            <a-select v-model="form.work_center_id"
              placeholder="Seleccionar el Centro de Trabajo"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in work_centers" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="sapCode" label="Clave de SAP" prop="sapCode" >
            <a-input v-model.trim="form.sap_code"/>
          </a-form-model-item>
          <a-form-model-item ref="code" label="Clave de Cliente" prop="code" >
            <a-input v-model.trim="form.code"/>
          </a-form-model-item>
          <a-form-model-item ref="name" label="Nombre comercial del Cliente" prop="name" >
            <a-input v-model.trim="form.name"/>
          </a-form-model-item>
          <a-form-model-item ref="business_name" label="Razón social del Cliente" prop="business_name" >
            <a-input v-model.trim="form.business_name "/>
          </a-form-model-item>
          <a-form-model-item ref="environmental_record" label="Registro Ambiental" prop="environmental_record" >
            <a-input v-model.trim="form.environmental_record"/>
          </a-form-model-item>
          <a-form-model-item ref="email" label="Correo Electrónico" prop="email" >
            <a-input v-model.trim="form.email"/>
          </a-form-model-item>
          <a-form-model-item ref="phone" label="Teléfono (XX-XXXX-XXXX)" prop="phone" >
            <a-input v-model.trim="form.phone"/>
          </a-form-model-item>
          <a-form-model-item ref="address" label="Dirección" prop="address" >
            <a-input v-model.trim="form.address"/>
          </a-form-model-item>
          <a-form-model-item ref="state" label="Estado" prop="state" >
            <a-select v-model="form.state_id"
              placeholder="Seleccionar Estado"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in states" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="municipality_name" label="Municipio" prop="municipality_name" >
            <a-input v-model.trim="form.municipality_name"/>
          </a-form-model-item>
          <a-form-model-item ref="zip_code" label="Código Postal" prop="zip_code" >
            <a-input v-model.trim="form.zip_code"/>
          </a-form-model-item>
          <a-form-model-item ref="accountingType" label="Tipo de Contabilización" prop="accountingType" >
            <a-select v-model="form.accounting_type_id"
              placeholder="Seleccionar Tipo de Contabilización"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in accounting_types" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="accountingSpecialType" label="Tipo de Contabilización Especial" prop="accountingSpecialType" >
            <a-select v-model="form.accounting_special_type_id"
                      placeholder="Seleccionar Tipo de Contabilización Especial"
                      show-search
                      :filter-option="filterOption"
            >
              <a-select-option  v-for="option in accounting_special_types" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <label for="collector" class="pt-4">¿Es Recolector?</label>
          <b-form-select
            v-model="form.is_collector"
            :options="collectorOptions"
            class="mb-3"
            value-field="item"
            text-field="name"
          ></b-form-select>
          <label for="credit_available_check" class="pt-4">¿Cuenta con Crédito?</label>
          <b-form-select
            v-model="form.credit_available_check"
            :options="collectorOptions"
            class="mb-3"
            value-field="item"
            text-field="name"
          ></b-form-select>
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
            <responsive-button v-if="this.id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject"
                          @Modal="OpenModal" @Filter="getData"
                          @searchInput="searchInput = $event"
    >
      <div slot="details" slot-scope="record">
        <small><strong>Recolector:</strong>
          <b-icon v-if="record.record.is_collector == true" icon="check-circle-fill" variant="success"></b-icon>
          <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
        </small><br>
        <small><strong>Tipo de Cliente: </strong>{{record.record.clientTypes.name}}<br></small>
        <small><strong>Centro de Trabajo: </strong>{{record.record.workCenters.name}}<br></small>
        <small><strong>Estado: </strong>{{record.record.states.name}}<br></small>
        <small><strong>Tipo de Contabilización: </strong>{{record.record.accountingTypes.name}}<br></small>
        <small><strong>Tipo de Contabilización Especial: </strong>{{record.record.accountingSpecialTypes.name}}</small>
      </div>
    </detail-catalog-table>
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogTable from '../components/table'
import DetailCatalogBar from '../components/searchBar'
import { notification, Modal } from 'ant-design-vue'
export default {
  name: 'clientForm',
  components: {
    DetailCatalogTable,
    DetailCatalogBar,
  },
  data() {
    return {
      // <!-- Constantes -->
      collectorOptions: [
        { item: true, name: 'SI' },
        { item: false, name: 'NO' },
      ],
      resource: 'clients',
      resourceName: 'Clientes',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Clientes',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Clave SAP',
            dataIndex: 'sap_code',
            key: 'sap_code',
            fixed: 'left',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.sap_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Clave de cliente',
            dataIndex: 'code',
            key: 'code',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Nombre comercial del Cliente',
            dataIndex: 'name',
            key: 'name',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Razón Social del Cliente',
            dataIndex: 'business_name',
            key: 'business_name',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.business_name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Registro Ambiental',
            dataIndex: 'environmental_record',
            key: 'environmental_record',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.environmental_record.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Correo Electrónico',
            dataIndex: 'email',
            key: 'email',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Teléfono',
            dataIndex: 'phone',
            key: 'phone',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.phone.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.address.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Municipio',
            dataIndex: 'municipality_name',
            key: 'municipality_name',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.municipality_name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Código Postal',
            dataIndex: 'zip_code',
            key: 'zip_code',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.zip_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Detalles',
            dataIndex: 'detail',
            key: 'detail',
            class: 'small',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            // fixed: 'right',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'name',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'sap_code', text: 'Clave SAP' },
        { value: 'code', text: 'Clave de cliente' },
        { value: 'name', text: 'Nombre Comercial del Cliente' },
        { value: 'business_name', text: 'Razón Social del Cliente' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        client_type_id: undefined,
        work_center_id: undefined,
        sap_code: '',
        name: '',
        code: '',
        business_name: '',
        environmental_record: '',
        email: '',
        phone: '',
        address: '',
        state_id: undefined,
        municipality_name: '',
        zip_code: '',
        accounting_type_id: undefined,
        accounting_special_type_id: undefined,
        is_collector: false,
        credit_available_check: false,
      },
      // Relaciones
      relationships: {
        states: {
          data: { id: 0, type: 'states' },
        },
        workCenters: {
          data: { id: 0, type: 'workCenters' },
        },
        clientTypes: {
          data: { id: 0, type: 'clientTypes' },
        },
        accountingTypes: {
          data: { id: 0, type: 'accountingTypes' },
        },
        accountingSpecialTypes: {
          data: { id: 0, type: 'accountingSpecialTypes' },
        },
      },
      // arrays selects
      client_types: [],
      work_centers: [],
      states: [],
      accounting_types: [],
      accounting_special_types: [],
      // Reglas de validación
      rules: {
        client_type_id: [
          { required: true, message: 'El campo Tipo de cliente es obligatorio.', trigger: 'blur' },
        ],
        work_center_id: [
          { required: true, message: 'El campo Centro de Trabajo es obligatorio.', trigger: 'blur' },
        ],
        sap_code: [
          { required: true, message: 'El campo Clave SAP es obligatoria.', trigger: 'blur' },
          { min: 2, max: 20, message: 'El campo Clave SAP debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Clave SAP debe ser de tipo texto.', trigger: 'blur' },
        ],
        name: [
          { required: true, message: 'El campo Nombre comercial del Cliente es obligatorio.', trigger: 'blur' },
          { min: 3, max: 400, message: 'El campo Nombre debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Nombre debe ser de tipo texto.', trigger: 'blur' },
        ],
        code: [
          { required: true, message: 'El campo Clave de Cliente es obligatorio.', trigger: 'blur' },
          { min: 2, max: 20, message: 'El campo Clave de Cliente debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Clave debe ser de tipo texto.', trigger: 'blur' },
        ],
        business_name: [
          { required: true, message: 'El campo Razón social del Cliente es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campo Nombre del Negocio debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Nombre del Negocio debe ser de tipo texto.', trigger: 'blur' },
        ],
        environmental_record: [
          { required: true, message: 'El campo Registro Ambiental es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campo Registro Ambiental debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Registro Ambiental debe ser de tipo texto.', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'El campo Correo Electrónico es obligatorio.', trigger: 'blur' },
          { min: 2, max: 100, message: 'El campo Correo Electrónico debe contener entre 2 y 100 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Correo debe ser de tipo texto.', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: 'El campo Número Telefónico es obligatorio.', trigger: 'blur' },
          { min: 2, max: 20, message: 'El campo Número Telefónico debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Número Telefónico debe ser de tipo numérico.', trigger: 'blur' },
          { type: 'regexp', regexp: '/^[0-9]{2}-[0-9]{4}-[0-9]{4}$/', message: 'El campo teléfono debe tener el formato XX - XXXX-XXXX' },
        ],
        address: [
          { required: true, message: 'El campo Dirección es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campo Dirección debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Dirección debe ser de tipo texto.', trigger: 'blur' },
        ],
        state_id: [
          { required: true, message: 'El campo Estado es obligatorio.', trigger: 'blur' },
        ],
        municipality_name: [
          { required: true, message: 'El campo Municipio es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campo Municipio debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Municipio debe ser de tipo texto.', trigger: 'blur' },
        ],
        zip_code: [
          { required: true, message: 'El campo Código Postal es obligatorio.', trigger: 'blur' },
          { min: 2, max: 10, message: 'El campo Código Postal debe contener entre 2 y 10 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Código Postal debe ser de tipo numérico.', trigger: 'blur' },
        ],
        accounting_type_id: [
          { required: true, message: 'El campo Tipo de Contabilización es obligatorio.', trigger: 'blur' },
        ],
        accounting_special_type_id: [
          { required: true, message: 'El campo Tipo de Contabilización Especial es obligatorio.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          'filter[search]': `${this.searchValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
          include: 'workCenters,states,accountingTypes,accountingSpecialTypes,clientTypes',
        },
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    getClient_types() {
      this.$store.dispatch('jv/get', ['clientTypes', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.client_types = objectArray(tableData)
        })
    },
    getWork_centers() {
      this.$store.dispatch('jv/get', ['workCenters', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.work_centers = objectArray(tableData)
        })
    },
    getStates() {
      this.$store.dispatch('jv/get', ['states', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.states = objectArray(tableData)
        })
    },
    getAccounting_types() {
      this.$store.dispatch('jv/get', ['accountingTypes', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.accounting_types = objectArray(tableData)
        })
    },
    getAccountingSpecialTypes() {
      this.$store.dispatch('jv/get', ['accountingSpecialTypes', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.accounting_special_types = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getClient_types()
      this.getWork_centers()
      this.getStates()
      this.getAccounting_types()
      this.getAccountingSpecialTypes()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.id = '0'
        this.form.client_type_id = undefined
        this.form.work_center_id = undefined
        this.form.sap_code = ''
        this.form.name = ''
        this.form.code = ''
        this.form.business_name = ''
        this.form.environmental_record = ''
        this.form.email = ''
        this.form.phone = ''
        this.form.address = ''
        this.form.state_id = undefined
        this.form.municipality_name = ''
        this.form.zip_code = ''
        this.form.accounting_type_id = undefined
        this.form.accounting_special_type_id = undefined
        this.form.is_collector = false
        this.form.credit_available_check = false
      } else {
        // Update case
        this.id = data.jsonApi.id
        this.form.sap_code = data.sap_code
        this.form.name = data.name
        this.form.code = data.code
        this.form.business_name = data.business_name
        this.form.environmental_record = data.environmental_record
        this.form.email = data.email
        this.form.phone = data.phone
        this.form.address = data.address
        this.form.municipality_name = data.municipality_name
        this.form.zip_code = data.zip_code
        this.form.is_collector = data.is_collector
        this.form.credit_available_check = data.credit_available_check
        this.form.work_center_id = data.workCenters.jsonApi.id
        this.form.client_type_id = data.clientTypes.jsonApi.id
        this.form.state_id = data.states.jsonApi.id
        this.form.accounting_type_id = data.accountingTypes.jsonApi.id
        this.form.accounting_special_type_id = data.accountingSpecialTypes.jsonApi.id
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          // build the relationships with the form data
          this.relationships.clientTypes.data.id = this.form.client_type_id
          this.relationships.workCenters.data.id = this.form.work_center_id
          this.relationships.states.data.id = this.form.state_id
          this.relationships.accountingTypes.data.id = this.form.accounting_type_id
          this.relationships.accountingSpecialTypes.data.id = this.form.accounting_special_type_id
          if (this.id === '0') {
            // Store Object
            formObject = {
              sap_code: `${this.form.sap_code}`,
              code: `${this.form.code}`,
              name: `${this.form.name}`,
              business_name: `${this.form.business_name}`,
              environmental_record: `${this.form.environmental_record}`,
              email: `${this.form.email}`,
              phone: `${this.form.phone}`,
              address: `${this.form.address}`,
              municipality_name: `${this.form.municipality_name}`,
              zip_code: `${this.form.zip_code}`,
              is_collector: this.form.is_collector,
              credit_available_check: this.form.credit_available_check,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              sap_code: `${this.form.sap_code}`,
              code: `${this.form.code}`,
              name: this.form.name,
              business_name: `${this.form.business_name}`,
              environmental_record: `${this.form.environmental_record}`,
              email: `${this.form.email}`,
              phone: `${this.form.phone}`,
              address: `${this.form.address}`,
              municipality_name: `${this.form.municipality_name}`,
              zip_code: `${this.form.zip_code}`,
              is_collector: this.form.is_collector,
              credit_available_check: this.form.credit_available_check,
              jsonApi: {
                type: this.resource,
                id: this.id,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Reset relationships
          this.resetRelathionships()
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetRelathionships() {
      this.relationships = {
        clientTypes: {
          data: { id: 0, type: 'clientTypes' },
        },
        workCenters: {
          data: { id: 0, type: 'workCenters' },
        },
        states: {
          data: { id: 0, type: 'states' },
        },
        accountingTypes: {
          data: { id: 0, type: 'accountingTypes' },
        },
        accountingSpecialTypes: {
          data: { id: 0, type: 'accountingSpecialTypes' },
        },
      }
    },
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
